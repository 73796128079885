import CircleElement from "../ui/CircleElement";

interface LogoProps {
  screenWidth: number
}

const LogoHeader = ({screenWidth} : LogoProps) => {
  const cProps = {
    backgroundColor: "#e5e5e5",
    fontColor: "#221815",
    size: 44,
    fontSize: 38,
    textShadow: "0px 0px 2px #e5e5e5, 0px 0px 2px #e5e5e5, 0px 2px 2px rgba(0,0,0,.75)",
    margin: '0px 5px'
  }
  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: screenWidth, margin: '0px auto'}}>
            <CircleElement {...cProps}>P</CircleElement>
            <CircleElement {...cProps}>O</CircleElement>
            <CircleElement {...cProps}>W</CircleElement>
            <CircleElement {...cProps}>E</CircleElement>
            <CircleElement {...cProps}>R</CircleElement>
            <CircleElement
              fontColor="#ffffff"
              backgroundColor="#fc0d0d"
              fontSize={24}
              size={44}
              textShadow="0px 0px 2px #000000, 0px 0px 2px #000000, 0px 2px 2px rgba(0,0,0,.75)"
              margin='0px 5px'
            >
              BLE
            </CircleElement>
    </div>
  );
};

export default LogoHeader;
