import { Game } from "../models/game";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ShareOutlined } from "@mui/icons-material";

interface ShareDialogProps {
  shareOpen: boolean;
  setShareOpen: (open: boolean) => void;
  setUploadOpen: (open: boolean) => void;
  game?: Game;
}

const SharePopUp = ({ shareOpen, setShareOpen, game }: ShareDialogProps) => {
  const [errMessage, setErrMessage] = useState("");

  return (
    <Dialog open={shareOpen}>
      <DialogContent sx={{textAlign: 'center'}}>
        <DialogContentText
          justifyContent="center"
          justifySelf="center"
          alignItems="center"
          variant="h5"
        >
          Share Your Board
          <br />
          <br />
        </DialogContentText>
        <div style={{display: 'inline-block', width: '150px', margin: '0px auto'}}>
            <pre>{game?.shareText}</pre>
        </div>
      </DialogContent>
      <DialogActions>
      {
            navigator.share !== undefined ?
                <IconButton onClick={()=>{
                    navigator.share({
                        text: game!.shareText
                    });
                }}>
                    <ShareOutlined/>
                </IconButton> : <></>
        }
        <Button onClick={()=>{
            navigator.clipboard.writeText(game?.shareText!)
        }
        }>Copy to Clipboard</Button>
        

        <Button onClick={() => setShareOpen(false)} sx={{ flexGrow: 1 }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharePopUp;