import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { useState } from "react";

import { auth } from "../firebase/firebase";
import { isLoggedIn, setLoggedOutToken, setProfile } from "../firebase/authServices";

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    return user.uid;
  } catch (err: any) {
    console.error(err);
    return null;
  }
};

interface LoginDialogProps {
  loginOpen: boolean;
  setLoginOpen: (open: boolean) => void;
  loadGame: () => Promise<void>;
}

const SignInPopUp = ({
  loginOpen,
  setLoginOpen,
  loadGame,
}: LoginDialogProps) => {
  const [errMessage, setErrMessage] = useState("");

  return (
    <Dialog open={loginOpen}>
      {!isLoggedIn() ? (
        <DialogContent>
          <DialogContentText
            sx={{textAlign: "center"}}
            variant="h5"
          >
            Sign In To Be Eligible
          </DialogContentText>

          <Box sx={{textAlign: 'center'}}>
            <button
              style={{
                border: "0px",
                padding: "0px",
                margin: "40px auto",
                backgroundColor: "inherit"
              }}
              onClick={async () => {
                const uid = await signInWithGoogle();
                const token = await auth.currentUser?.getIdToken(true);
                if (token) {
                  window.localStorage.setItem("token", token);
                  setProfile();
                  loadGame();
                  setLoginOpen(false);
                }
              }}
            >
              <img
                src="/signInWithGoogle.png"
                width="223px"
                height="40px"
                alt="Sign In With Google"
                style={{margin: "0px"}}
              />
            </button>
          </Box>
          {/* <Box sx={{textAlign:"center"}}>
            <Typography variant="body2" align="left" sx={{display: 'inline-block', maxWidth: '280px'}}>
                We will never send you an email. We use Google Firebase for authentication and analytics. That service uses cookies.
            </Typography>
          </Box> */}
        </DialogContent>
      ) : (
        <></>
      )}
      <DialogActions>
        {isLoggedIn() ? (
          <Button
            onClick={() => {
              auth.signOut();
              setLoggedOutToken();
              loadGame();
              setLoginOpen(false);
            }}
          >
            Log Out
          </Button>
        ) : (
          <></>
        )}

        <Button onClick={() => setLoginOpen(false)} sx={{ flexGrow: 1 }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignInPopUp;
