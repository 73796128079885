import React, { Component, useEffect, useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import { Game } from "../models/game";
import axios from "axios";

interface VictoryUploadProps {
  game?: Game;
  uploadOpen: boolean;
  setUploadOpen: (uploadOpen: boolean) => void;
  hasUploaded: boolean;
  setHasUploaded: (x: boolean) => void;
}

function VictoryUploadPopUp({
  game,
  uploadOpen,
  setUploadOpen,
  hasUploaded,
  setHasUploaded,
}: VictoryUploadProps) {
  console.log(uploadOpen);
  const [files, setFiles] = useState<File[]>([]);

  async function upload(file: File) {
    if (!game?.winCode) {
      return false;
    }
    const formData = new FormData();
    formData.append("winCode", game.winCode);
    formData.append("image", file);
    try {
      const response = await axios({
        method: "post",
        url: process.env["REACT_APP_API_ROOT"]! + "/gameUpload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  useEffect(() => {
    if (files.length /* && !hasUploaded*/) {
      upload(files[0]).then(() => {
        setUploadOpen(false);
        setHasUploaded(true);
      });
    }
  }, [files, hasUploaded]);

  function handleClose() {
    setUploadOpen(false);
  }

  function handleSave(files: File[]) {
    //Saving files to state for further use and closing Modal.
    setFiles(files);
  }

  return (
    <DropzoneDialog
      open={uploadOpen}
      onSave={handleSave}
      acceptedFiles={["image/jpeg", "image/png"]}
      showPreviews={true}
      filesLimit={1}
      maxFileSize={5000000}
      onClose={handleClose}
    />
  );
}

export default VictoryUploadPopUp;
