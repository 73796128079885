import { auth } from './firebase';

// TODO: IF WE EVER MAKE THIS FOR MONEY OR ANYTHING, HARDEN THIS SHIT

const randomAlnum = (length: number = 30) => {
    const alpha = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
        const idx = Math.floor(Math.random() * alpha.length);
        result += alpha.slice(idx, idx + 1);
    }
    return result;
}

const getToken = () => {
    const storageToken = window.localStorage.getItem('token');
    return storageToken ?? setLoggedOutToken();
}

export const isLoggedIn = () => {
    const storageToken = window.localStorage.getItem('token');
    if (!storageToken) return false;
    if (storageToken.indexOf('::logged_out::') > -1) return false;
    return true;
}

export const setLoggedOutToken = () => {
    const storageToken = window.localStorage.getItem('token');
    if (storageToken && storageToken?.indexOf('::logged_out::') > -1) {
        return storageToken;
    }
    const loggedOutToken = window.localStorage.getItem('logged_out_token');
    if (loggedOutToken) {
        window.localStorage.setItem('token', loggedOutToken);
        window.localStorage.removeItem('profile_url');
        return loggedOutToken;
    }
    const newToken = '::logged_out::' + randomAlnum();
    window.localStorage.setItem('token', newToken);
    window.localStorage.removeItem('profile_url');
    window.localStorage.setItem('logged_out_token', newToken);
    return newToken;
}

export const setAuthToken = async (loadCurrentUser : boolean = false) => {
    let token;
    if (loadCurrentUser = true) await auth.currentUser?.getIdToken();
    if (token) {
        window.localStorage.setItem('token', token);
        if (auth.currentUser!.photoURL) {
            window.localStorage.setItem('profile_url', auth.currentUser!.photoURL);
        }
        else {
            window.localStorage.removeItem('profile_url');
        }
    }
    else {
        token = setLoggedOutToken();
    }
    return token;
}

export const getProfile = () => {
    const profileUrl = window.localStorage.getItem('profile_url');
    return profileUrl ?? null;
}

export const setProfile = () => {
    const profileUrl = auth.currentUser?.photoURL;
    if (profileUrl) {
        window.localStorage.setItem('profile_url', profileUrl);
    }
    else {
        window.localStorage.removeItem('profile_url');
    }
}

export default getToken;