import { Game } from "../models/game";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ShareOutlined } from "@mui/icons-material";
import axios from "axios";

interface WinnerProps {
  winnerOpen: boolean;
  setWinnerOpen: (open: boolean) => void;
}

const WinnerPopUp = ({ winnerOpen, setWinnerOpen }: WinnerProps) => {
  const [winData, setWinData] = useState<any>({});

  useEffect(() => {
    axios
      .get(process.env["REACT_APP_API_ROOT"]! + "/winnerGalleryData")
      .then((result) => {
        if (!result?.data) {
          console.error("no data for winner");
        } else {
          setWinData(result.data);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <Dialog open={winnerOpen}>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogContentText><Typography variant="h6">Most recent winner:</Typography></DialogContentText>
        {winData.image ? (
          <img
            src={winData.image}
            alt="Uploaded by game winner"
            style={{ margin: "0px auto" }}
          />
        ) : (
          <DialogContentText>No winners yet</DialogContentText>
        )}
        <DialogContentText><Typography variant="body2">(You must be logged in before solving the puzzle to be able to post a picture)</Typography></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setWinnerOpen(false)} sx={{ flexGrow: 1 }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WinnerPopUp;
