//import { Box, Typography } from '@mui/material'
import { Typography } from "@mui/material";
import "./CircleElement.css";

interface CircleElementProps {
  size?: number;
  fontSize?: number;
  backgroundColor?: string;
  fontColor?: string;
  margin?: number | string;
  children?: any;
  fontFamily?: string;
  textShadow?: string;
  topNudge?: number;
  leftNudge?: number;
}

const CircleElement = ({
  size = 24,
  fontSize = 16,
  backgroundColor = "#333",
  fontColor = "#fff",
  margin = 2,
  fontFamily = "Roboto, sans-serif",
  children,
  textShadow = "initial",
  topNudge = 0,
  leftNudge = 0
}: CircleElementProps) => {
  return (
    <div
      className="CircleElement"
      style={{
        display: "inline-block",
        width: `${size}px`,
        height: `${size}px`,
        position: "relative",
        margin: (typeof(margin) === "string") ? margin : `${margin}px`
      }}
    >
      <div
        className="circle"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: backgroundColor,
        }}
      ></div>
      <div
        className="text"
        style={{
          width: `${size}px`,
          color: fontColor,
          textShadow: textShadow
        }}
      >
        <Typography sx={{fontSize: `${fontSize}px`, lineHeight: `${size}px`, position: 'relative', top: topNudge, left: leftNudge}} component="span">{children}</Typography>
      </div>
    </div>
  );
};

export default CircleElement;
