
export enum CellState {
  Inactive,
  ActiveRow,
  GradedWrong,
  GradedMisplaced,
  GradedMisplacedPower,
  GradedCorrect,
  GradedCorrectPower
}

export class GameCell {
    public digit?: number;
    public firstDigit: number | null;
    public color?: string;
    public cellNum: number;
    public state: CellState;

    constructor(args: { digit?: number, color?: string, cellNum: number }) {
      this.digit = args?.digit ?? undefined;
      this.color = args?.color ?? undefined;
      this.firstDigit = null;
      this.cellNum = args.cellNum;
      this.state = CellState.Inactive;
    }

    public pressNumber(number : number) : number {
      if (this.firstDigit === null && this.digit === undefined) {
        this.firstDigit = number;
        return 0;
      }
      else {
        this.digit = 10 * (this.firstDigit ?? 0) + number;
        return 1;
      }
    }

    public pressBackspace() : number{
      if (this.digit !== undefined) {
        this.digit = undefined;
        this.firstDigit = this.firstDigit ?? 0;
        return 0;
      }
      else if (this.firstDigit !== null) {
        this.firstDigit = null;
        return -1;
      }
      else {
        return -1;
      }
    }

    public deleteNumber() : number {
      this.digit = undefined;
      this.firstDigit = null;
      return -1;
    }

    public get showNumber() : string {
      if (this.firstDigit === null && this.digit === undefined) {
        return ' '
      }
      else if (this.digit === undefined) {
        return `${this.firstDigit}_`;
      }
      else {
        if (this.digit <= 9) {
          return `0${this.digit}`;
        }
        else {
          return `${this.digit}`
        }
      }
    }
  }