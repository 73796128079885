import { Game } from "./game";
import { GameCell, CellState } from "./gameCell";

export class GameRow {
    public cells: GameCell[];
    public activeCellIdx: number;
    public rowNum: number;
    public game: Game;
    public isWin: boolean = false;

    constructor(args: { width: number, rowNum: number, game: Game }) {
        this.cells = Array.from(Array(args.width).keys()).map((key) => new GameCell({ cellNum: key }));
        this.activeCellIdx = -1;
        this.rowNum = args.rowNum;
        this.game = args.game;
    }

    public activate() {
        this.cells.map((cell) => {
            cell.digit = undefined;
            cell.state = CellState.ActiveRow;
        });
        this.activeCellIdx = 0;
    }

    public deactivate() {
        this.activeCellIdx = -1;
    }

    public addNumber(number: number) {
        if (this.activeCell) {
            const forward = this.activeCell.pressNumber(number);
            this.activeCellIdx += forward ?? 0;
        }
    }

    public setNumber(number: number) {
        if (this.activeCell) {
            this.activeCell.firstDigit = Math.floor(number / 10);
            this.activeCell.digit = number;
            this.activeCellIdx++;
        }
    }

    public backSpace() {
        if (this.activeCellIdx >= this.cells.length) {
            this.activeCellIdx = this.cells.length - 1;
        }
        if (this.activeCell) {
            if (this.activeCell.firstDigit === null && this.activeCellIdx > 0) {
                this.activeCellIdx--;
            }
            this.activeCell.pressBackspace();
        }
    }

    public deleteNum() {
        if (this.activeCellIdx >= this.cells.length) {
            this.activeCellIdx = this.cells.length - 1;
        }
        if (this.activeCell) {
            if (this.activeCell.firstDigit === null && this.activeCellIdx > 0) {
                this.activeCellIdx--;
            }
            this.activeCell.deleteNumber();
        }
    }

    public get activeCell(): GameCell | null {
        if (this.activeCellIdx < 0) return null;
        if (this.activeCellIdx >= this.cells.length) return null;
        return this.cells[this.activeCellIdx];
    }

    public canSubmit(): boolean {
        if (this.activeCellIdx >= this.cells.length) return true;
        return false;
    }

    public guessVector(): number[] {
        if (!this.canSubmit) {
            throw new Error('cannot retrieve guessVector of incomplete row');
        }
        return this.cells.map(cell => cell.digit!);
    }

    public setCellsFromVector(digits: number[]) {
        for (let i = 0; i < digits.length; i++) {
            this.cells[i].firstDigit = Math.floor(digits[i] / 10);
            this.cells[i].digit = digits[i];
        }
    }

    public applyGradeVector(grades: number[], ) {
        let win = true;
        for (let i = 0; i < grades.length; i++) {
            this.game.setGuessed(this.cells[i].digit!, grades[i]);
            if (grades[i] === 0) {
                this.cells[i].state = CellState.GradedWrong;
                win = false;
            }
            else if (grades[i] === 1) {
                if (i === this.cells.length - 1) {
                    this.cells[i].state = CellState.GradedMisplacedPower;
                }
                else {
                    this.cells[i].state = CellState.GradedMisplaced;
                }
                win = false;
            }
            else if (grades[i] === 2) {
                if (i === this.cells.length - 1) {
                    this.cells[i].state = CellState.GradedCorrectPower;
                }
                else {
                    this.cells[i].state = CellState.GradedCorrect;
                }
            }
        }
        if (win) {
            this.isWin = true;
        }
    }

    refreshEmpty() {
        for (let i = 0; i < this.cells.length; i++) {
            this.cells[i].firstDigit = null;
            this.cells[i].digit = undefined;
            this.cells[i].state = CellState.Inactive;
        }
    }
}