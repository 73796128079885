import { GameRow } from "./gameRow";
import { CellState, GameCell } from "./gameCell";
import { KeyboardEvent, DispatchWithoutAction } from "react";
import axios from "axios";
import getToken from "../firebase/authServices";
import { range, delay } from "../util";
import config from "../config";


const digitKeys: any = {
    'Digit0': 0,
    'Digit1': 1,
    'Digit2': 2,
    'Digit3': 3,
    'Digit4': 4,
    'Digit5': 5,
    'Digit6': 6,
    'Digit7': 7,
    'Digit8': 8,
    'Digit9': 9
}

let emojiDict: any = {}
emojiDict[CellState.GradedWrong] = '⬛';
emojiDict[CellState.GradedMisplaced] = '🟨';
emojiDict[CellState.GradedMisplacedPower] = '🟧';
emojiDict[CellState.GradedCorrect] = '🟩';
emojiDict[CellState.GradedCorrectPower] = '🟥';

export interface GameConfig {
    minValue?: number,
    maxValue?: number,
    numValues?: number,
    numGuesses?: number,
    gameNumber?: number,
    gameVariant?: number,
    guesses?: number[][],
    grades?: number[][],
    winCode?: string,
    hasUploaded?: boolean
}

export class Game {
    public rows: GameRow[];
    public rowIdx: number;
    public numCells: number;
    public numTries: number;
    public minValue: number;
    public maxValue: number;
    public playing: boolean = true;
    public guessed: any = {};
    public gameNumber?: number;
    public gameVariant?: number;
    public winCode?: string;
    public hasUploaded?: boolean;
    public refreshDispatch: DispatchWithoutAction;
    public sharePopup: () => void;
    public uploadPopup: () => void;

    constructor({ numValues, minValue, maxValue, numGuesses, guesses, grades, gameNumber, gameVariant, winCode, hasUploaded }: GameConfig, refreshDispatch: DispatchWithoutAction, sharePopup: () => void, uploadPopup: () => void) {
        this.numCells = numValues ?? 8;
        this.numTries = numGuesses ?? 6;
        this.minValue = minValue ?? 0;
        this.maxValue = maxValue ?? 60;
        this.gameNumber = gameNumber;
        this.gameVariant = gameVariant;
        this.winCode = winCode;
        this.hasUploaded = hasUploaded;
        this.refreshDispatch = refreshDispatch;
        this.sharePopup = sharePopup;
        this.uploadPopup = uploadPopup;

        this.rows = range(this.numTries).map(
            (key) => new GameRow({ width: this.numCells, rowNum: key, game: this })
        );

        if (!guesses || guesses.length === 0) {
            this.rowIdx = 0;
        }
        else {
            for (let i = 0; i < guesses.length; i++) {
                this.rows[i].setCellsFromVector(guesses[i]);
                this.rows[i].applyGradeVector(grades![i]);
            }
            this.rowIdx = guesses.length;
        }

        for (let i = this.rowIdx; i < this.rows.length; i++) {
            this.rows[i].refreshEmpty();
        }
        if (this.rowIdx === 0 || (this.rowIdx < this.rows.length && !this.rows[this.rowIdx - 1].isWin)) {
            this.rows[this.rowIdx].activate();
            this.playing = true;
        }
        else {
            this.playing = false;
        }

        refreshDispatch();
    }

    setActiveRow(rowIndex: number) {
        this.rowIdx = rowIndex;
        this.rows.map((row) => row.deactivate());
        this.activeRow?.activate();
    }

    keyDown(e: KeyboardEvent): void {
        if (!this.playing) return;
        if (digitKeys[e.code] !== undefined) {
            this.rows[this.rowIdx].addNumber(digitKeys[e.code]);
            this.refreshDispatch();
        }
        else if (e.code === 'Backspace') {
            this.rows[this.rowIdx].backSpace();
            this.refreshDispatch();
        }
        else if (e.code === 'Enter') {
            if (this.activeRow?.canSubmit() === true) {
                this.scoreActiveRow().then(() => this.refreshDispatch());
            }
        }
    }

    screenKeyboard(keyType: 'Digit' | 'Backspace' | 'Enter', value: number) {
        if (!this.playing) return;
        if (keyType === 'Backspace') {
            this.activeRow?.deleteNum();
            this.refreshDispatch();
        }
        else if (keyType === 'Enter') {
            if (this.activeRow?.canSubmit() === true) {
                this.scoreActiveRow().then(() => this.refreshDispatch());
            }
        }
        else {
            this.activeRow?.setNumber(value);
            this.refreshDispatch();
        }
    }

    async scoreActiveRow() {
        if (this.activeRow?.canSubmit() !== true) {
            return;
        }
        const result = await axios.post(config.apiRoot + '/grade', {
            guess: this.activeRow?.guessVector()
        }, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        if (result.data && result.data['grades'] && result.data['grades'].length === this.numCells) {
            if (result.data.gameNumber) this.gameNumber = result.data.gameNumber;
            if (result.data.gameVariant) this.gameVariant = result.data.gameVariant;

            const grades = result.data.grades;
            let win = true;
            for (let i = 0; i < this.numCells; i++) {
                const digit = this.activeRow.cells[i].digit!;
                if (grades[i] === 1) {
                    if (i === this.numCells - 1) {
                        this.activeRow.cells[i].state = CellState.GradedMisplacedPower;
                    }
                    else {
                        this.activeRow.cells[i].state = CellState.GradedMisplaced;
                    }
                    win = false;
                    this.setGuessed(digit, 1);
                }
                else if (result.data['grades'][i] === 0) {
                    this.activeRow.cells[i].state = CellState.GradedWrong;
                    win = false;
                    this.setGuessed(digit, 0);
                }
                else if (result.data['grades'][i] === 2) {
                    if (i === this.numCells - 1) {
                        this.activeRow.cells[i].state = CellState.GradedCorrectPower;
                    }
                    else {
                        this.activeRow.cells[i].state = CellState.GradedCorrect;
                    }
                    this.setGuessed(digit, 2);
                }
            }
            this.activeRow.deactivate();
            this.rowIdx++;
            if (win || this.rowIdx >= this.numTries) {

                if (result.data.winCode) {
                    this.winCode = result.data.winCode;
                    this.hasUploaded = result.data.hasUploaded;
                }

                this.playing = false;
                if (this.winCode) {
                    delay(1500).then(() => this.uploadPopup());
                }
                else {
                    delay(1500).then(() => this.sharePopup());
                }
            }
            else {
                this.activeRow?.activate();
            }
        }
        else {
            console.error('error grading row');
        }
        this.refreshDispatch();
    }

    setGuessed(digit: number, value: number) {
        const oldValue = this.guessed[digit];
        let newVal: number | undefined;
        if (value === 2) {
            newVal = 2;
        }
        else if (value === 1) {
            if (oldValue === 0 || oldValue === undefined) {
                newVal = 1;
            }
        }
        else {
            if (oldValue === undefined) {
                newVal = 0;
            }
        }
        if (newVal !== undefined) {
            this.guessed[digit] = newVal;
        }
    }

    public get activeRow(): GameRow | null {
        if (this.rowIdx < 0 || this.rowIdx >= this.numTries) {
            return null;
        }
        return this.rows[this.rowIdx];
    }

    get shareText(): string | undefined {
        if (this.playing) return undefined;
        let shareText = `Powerble ${this.gameNumber}.${this.gameVariant}\n`;
        for (let i = 0; i < this.rowIdx; i++) {
            for (let j = 0; j < this.rows[i].cells.length; j++) {
                shareText += emojiDict[this.rows[i].cells[j].state]
            }
            shareText += '\n';
        }
        shareText += 'https://powerble.com';
        return shareText;
    }
}