import { Game } from "../models/game";
import { GuessRow } from "./GuessRow";
import CircleElement from "../ui/CircleElement";

const DEFAULT_CELLS = 8;

interface GameGridProps {
  game: Game | undefined,
  screenWidth: number
}

function GameGrid({ game, screenWidth } : GameGridProps) {
  const cellSpace = () =>
    screenWidth / (game?.numCells ?? DEFAULT_CELLS);
  const cellDim = () => cellSpace() - 8;
  const cellFontSize = () => cellDim() * 0.5;

  return (
    <div className="GameGrid" style={{
      width: `${screenWidth}px`
    }}>
      {(game?.rows ?? []).map((row) => (
        <GuessRow
          row={row}
          key={row.rowNum}
          cellDim={cellDim()}
          cellFontSize={cellFontSize()}
        />
      ))}
    </div>
  );
}

export default GameGrid;
