import React, { useEffect, useReducer, useState } from "react";
import {
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import GameGrid from "./components/GameGrid";
import { Game, GameConfig } from "./models/game";
import axios from "axios";
import Keyboard from "./components/Keyboard";
import "./index.css";
import SignInPopUp from "./components/SignInPopUp";
import getToken, { getProfile, setAuthToken } from "./firebase/authServices";
import { auth } from "./firebase/firebase";
import SharePopUp from "./components/SharePopUp";
import Popups from "./components/Popups";
import Header from "./components/Header";
import LogoHeader from "./components/LogoHeader";

function App() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [game, setGame] = useState<Game | undefined>(undefined);
  const [gameConfig, setGameConfig] = useState<GameConfig>({});
  const [loginOpen, setLoginOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [winnerOpen, setWinnerOpen] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);

  const loadGame = async () => {
    const result = await axios.get<GameConfig>(
      process.env["REACT_APP_API_ROOT"]! + "/gameConfig",
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    if (result && result.data) {
      setGameConfig(result.data);
    }
  };

  const popUpProps = {
    game,
    loginOpen,
    setLoginOpen,
    shareOpen,
    setShareOpen,
    loadGame,
    uploadOpen,
    setUploadOpen,
    winnerOpen,
    setWinnerOpen,
    hasUploaded,
    setHasUploaded,
    helpOpen,
    setHelpOpen
  };

  const logoProps = { screenWidth: 350 };

  const gameGridProps = { game, screenWidth: 350 };

  const keyboardProps = { game, screenWidth: 350 };

  const headerProps = { game, setLoginOpen, setWinnerOpen, hasUploaded, setHelpOpen }

  auth.onAuthStateChanged((user) => {
    if (gameConfig.numValues === undefined) {
      setAuthToken().then(() => loadGame());
    }
  });

  const sharePopup = () => {
    setShareOpen(true);
  };

  const uploadPopup = () => {
    setUploadOpen(true);
  }

  useEffect(() => {
    console.log(gameConfig);
    if (gameConfig.numValues) {
      setGame(new Game(gameConfig, forceUpdate, sharePopup, uploadPopup));
    }
    else {
      loadGame();
    }
  }, [gameConfig]);

  return (
    <Box
      tabIndex={0}
      className="App"
      onKeyDown={(e) => {
        game?.keyDown(e);
      }}
    >
      {/* <Header {...headerProps} /> */}
      <Popups {...popUpProps} />
      <Header {...headerProps} />
      <Box style={{ textAlign: "center", marginTop: '38px'}}>
        <Box
          style={{
            display: "inline-block",
            margin: "2px auto 5px auto",
            width: gameGridProps.screenWidth,
          }}
        >
          <LogoHeader {...logoProps} />
          <GameGrid {...gameGridProps} />
        </Box>
        <Box>
          <Keyboard {...keyboardProps} />
        </Box>
      </Box>
    </Box>
  );
}

export default App;
