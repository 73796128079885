import { Game } from "../models/game";
import HelpPopUp from "./HelpPopup";
import SharePopUp from "./SharePopUp";
import SignInPopUp from "./SignInPopUp";
import VictoryUploadPopUp from "./VictoryUploadPopUp";
import WinnerPopUp from "./WinnerPopUp";

interface PopupsProps {
  shareOpen: boolean;
  setShareOpen: (x: boolean) => void;
  game?: Game;
  loginOpen: boolean;
  setLoginOpen: (x: boolean) => void;
  loadGame: () => Promise<void>;
  uploadOpen: boolean;
  setUploadOpen: (x: boolean) => void;
  winnerOpen: boolean;
  setWinnerOpen: (x: boolean) => void;
  hasUploaded: boolean;
  setHasUploaded: (x: boolean) => void;
  helpOpen: boolean;
  setHelpOpen: (x: boolean) => void;
}

const Popups = (props: PopupsProps) => {
  return (
    <>
      <SharePopUp {...props} />
      <SignInPopUp {...props} />
      <VictoryUploadPopUp {...props} />
      <WinnerPopUp {...props} />
      <HelpPopUp {...props} tag="help"/>
    </>
  );
};

export default Popups;
