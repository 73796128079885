import { Game } from "../models/game";
import BackspaceIcon from "@mui/icons-material/BackspaceRounded";
import { IconButton } from "@mui/material";
import CircleElement from "../ui/CircleElement";

const buttonStyle = {
  border: "none",
  borderRadius: "3px",
  padding: "0",
  fontSize: "18px",
  margin: "0px 1px 0px 1px",
  color: "black",
};

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 60;

function Key(props: { game?: Game; value: number }) {
  let color = "#cccccc";
  if (props.game?.guessed[props.value] === 0) {
    color = "#888888";
  } else if (props.game?.guessed[props.value] === 1) {
    color = "#eeee22";
  } else if (props.game?.guessed[props.value] === 2) {
    color = "#33ff33";
  }

  if (props.value === -2) {
    return (
      <IconButton
        onClick={() => props.game?.screenKeyboard("Enter", -1)}
        style={{
          backgroundColor: color,
          ...buttonStyle,
          padding: "0px 2px 2px 2px",
          marginLeft: 12,
        }}
      >
        return
      </IconButton>
    );
  } else if (props.value === -1) {
    return (
      <IconButton
        onClick={() => props.game?.screenKeyboard("Backspace", -1)}
        style={{ marginRight: 12 }}
      >
        <BackspaceIcon fontSize="medium" sx={{ color: color }} />
      </IconButton>
    );
  } else {
    const digit1 = Math.floor(props.value / 10);
    const digit2 = props.value % 10;
    return (
      <IconButton
        onClick={() => props.game?.screenKeyboard("Digit", props.value)}
        style={{ backgroundColor: "none", ...buttonStyle }}
      >
        <CircleElement
          backgroundColor={color}
          fontColor="#000"
          size={22}
          fontSize={14}
          margin={3}
          topNudge={-2}
        >{`${digit1}${digit2}`}</CircleElement>
      </IconButton>
    );
  }
}

function Keyboard(props: { game?: Game }) {
  const rowSize = 10;
  const min = () => props.game?.minValue ?? DEFAULT_MIN;
  const max = () => props.game?.maxValue ?? DEFAULT_MAX;
  const numRows = () => Math.ceil((max() ?? -min() + 1) / rowSize);

  let values = [];
  let rows: number[][] = [];
  if (props.game) {
    let valCount = min();
    for (let i = 0; i < numRows(); i++) {
      let row: number[] = [];
      if (i === numRows() - 1) {
        row.push(-1);
      }
      for (let j = 0; j < rowSize + (i % 2); j++) {
        if (valCount <= max()) {
          row.push(valCount);
          valCount++;
        }
      }
      if (i === numRows() - 1) {
        row.push(-2);
      }
      rows.push(row);
    }
  }

  return (
    <div style={{ display: "inline-block" }}>
      {rows.map(
        (row, index) => (
          <div key={index} style={{
            marginTop: index === rows.length - 1 ? 0 : 4
          }}>
            {row.map((value, idx) => (
              <Key game={props.game} value={value} key={idx}/>
            ))}
          </div>
        )
        // val === -3 ? (
        //   <br key={Math.random()} />
        // ) : (
        //   <Key game={props.game} value={val} key={val} />
        // )
      )}
    </div>
  );
}

export default Keyboard;
