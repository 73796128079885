import { Game } from "../models/game";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ShareOutlined } from "@mui/icons-material";
import axios from "axios";
import ReactMarkdown from "react-markdown";

interface DocumentProps {
  helpOpen: boolean,
  setHelpOpen: (arg0: boolean)=>void,
  tag: string
}

const HelpPopUp = ({ helpOpen, setHelpOpen, tag } : DocumentProps) => {
  const markdown = 
`## POWERBLE RULES!

Choose any combination of 6 numbers. 
You get 6 guesses!
If you get them all right before your last guess, you 

*WIN the POWERBLE!!!*

🟩
The right number in the right spot!

🟨
This number is in the solution but NOT in this spot

### What’s up with the Red One?
That’s the Powerble

### What is the significance of the Powerble?
It’s the Red One

### What happens if I win the Powerble?
If you are logged in and you win the Powerble, you get to choose and upload an image to be displayed in the WINNER’S GALLERY

### What if I’m not logged in?
If you correctly guess the solution but are NOT logged in, you will NOT be able to upload an image, and your friends will just have to believe you when you tell them that you won the Powerble`;

  // useEffect(() => {
  //   axios
  //     .get(process.env["REACT_APP_API_ROOT"]! + "/winnerGalleryData")
  //     .then((result) => {
  //       if (!result?.data) {
  //         console.error("no data for winner");
  //       } else {
  //         setWinData(result.data);
  //       }
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  // }, []);

  return (
    <Dialog open={helpOpen}>
      <DialogContent>
        <ReactMarkdown className="mdText">{markdown}</ReactMarkdown>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setHelpOpen(false)} sx={{ flexGrow: 1 }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpPopUp;
