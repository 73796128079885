import { GameCell, CellState } from "../models/gameCell";
import "./GuessCell.css";
import { useEffect, useRef } from "react";

const stateClasses: any = {};

stateClasses[CellState.Inactive] = "inactive";
stateClasses[CellState.ActiveRow] = "activeRow";
stateClasses[CellState.GradedWrong] = "gradedWrong";
stateClasses[CellState.GradedMisplaced] = "gradedMisplaced";
stateClasses[CellState.GradedMisplacedPower] = "gradedMisplacedPower";
stateClasses[CellState.GradedCorrect] = "gradedCorrect";
stateClasses[CellState.GradedCorrectPower] = "gradedCorrectPower";

interface GuessCellProps {
  cell: GameCell;
  cellDim: number;
  cellFontSize: number;
  redCell?: boolean;
}

export function GuessCell({ cell, cellDim, cellFontSize, redCell }: GuessCellProps) {
  const cellRef = useRef<HTMLTableCellElement>(null);
  useEffect(() => {
    for (let stateKey in CellState) {
      // weird pattern to iterate over possible keys in enum
      if (isNaN(Number(stateKey))) continue;
      const state = Number(stateKey) as CellState;
      if (
        cell.state !== state &&
        cellRef.current!.classList.contains(stateClasses[state])
      ) {
        cellRef.current!.classList.toggle(stateClasses[state], false);
      } else if (
        cell.state === state &&
        !cellRef.current!.classList.contains(stateClasses[state])
      ) {
        cellRef.current!.classList.toggle(stateClasses[state], true);
      }
    }
  }, [cell.state]);

  let className = "guessCell";

  if (redCell === true) className = "redCell";

  return (
    <div
      ref={cellRef}
      className={className}
    >
      <div className="inside">{cell.showNumber}</div>
    </div>
  );
}
