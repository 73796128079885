import { GameRow } from "../models/gameRow";
import { GuessCell } from "./GuessCell";

interface GuessRowProps {
  row: GameRow,
  cellDim: number,
  cellFontSize: number
}

const rowStyle : React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  marginBottom: '5px'
}

export function GuessRow({
  row,
  cellDim,
  cellFontSize
} : GuessRowProps) {
  return (
    <div className="GameRow" style={rowStyle}>
      {row.cells.map((cell) => (
        <GuessCell
          cell={cell}
          key={cell.cellNum}
          cellDim={cellDim}
          redCell={cell.cellNum === row.cells.length - 1}
          cellFontSize={cellFontSize}
        />
      ))}
    </div>
  );
}
