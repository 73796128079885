import { AppBar, Container, IconButton, Toolbar, Typography } from "@mui/material";
import { getProfile } from "../firebase/authServices";
import PersonIcon from "@mui/icons-material/Person";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import HelpIcon from "@mui/icons-material/Help";
import { Game } from "../models/game";
import CircleElement from "../ui/CircleElement";
import { FilePresent, UploadFile } from "@mui/icons-material";

interface HeaderProps {
  setLoginOpen: (x: boolean) => void;
  setWinnerOpen: (x: boolean) => void;
  game?: Game;
  hasUploaded: boolean;
  setHelpOpen: (x: boolean) => void;
}

const Header = (props: HeaderProps) => {
  console.log(props.game?.winCode);
  return (
    <AppBar
      sx={{
        borderBottom: "solid 1px #4e5053",
        height: "36px",
        backgroundColor: "black!important",
      }}
    >
      <Container sx={{maxWidth: 428}}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>
          <IconButton onClick={()=>props.setHelpOpen(true)} sx={{marginRight: "8px"}}>
            <HelpIcon sx={{ color: "white" }}/>
          </IconButton>
        </span>
        {props.game?.playing === false ? (
          <span>
            <IconButton
              onClick={() => props.game!.sharePopup()}
              sx={{ marginRight: "8px" }}
            >
              <ShareIcon sx={{ color: "#33ff33" }} />
            </IconButton>
          </span>
        ) : (
          <></>
        )}
        {props.game?.winCode !== undefined && !props.hasUploaded ? (
          <IconButton onClick={() => props.game!.uploadPopup()}>
            <UploadFile sx={{ color: "#33ff33" }} />
          </IconButton>
        ) : (
          <IconButton onClick={() => props.setWinnerOpen(true)}>
            <TrophyIcon sx={{ color: "#ffffff"}}/>
          </IconButton>
        )}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ marginRight: 0 }}
          onClick={() => props.setLoginOpen(true)}
        >
          {getProfile() ? (
            <img
              src={getProfile()!}
              width={24}
              height={24}
              style={{ borderRadius: 12 }}
              alt="user portrait"
            />
          ) : (
            <PersonIcon />
          )}
        </IconButton>
      </div>
      </Container>

      {/* <Toolbar
          variant="dense"
          sx={{ backgroundColor: "#444444", paddingRight: "0px", height: "36px", minHeight: "36px" }}
        >
          <Typography variant="h1" component="h5" sx={{ flexGrow: 1 }}>
            {(['P','O','W','E','R']).map(letter=><CircleElement
              backgroundColor="#222"
              fontColor="#ddd"
              key={letter}
            >{letter}</CircleElement>)}
            <CircleElement backgroundColor="#ff3333" fontColor="#fff" fontSize={12}>BLE</CircleElement>
          </Typography>
          {
              props.game?.playing === false ? 
                <IconButton
                    onClick={()=>props.game!.sharePopup()}
                    sx={{marginRight: "8px"}}
                >
                    <ShareIcon sx={{color: "#33ff33"}} />
                </IconButton>
              : <></>
          }
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ marginRight: 0 }}
            onClick={() => props.setLoginOpen(true)}
          >
            {getProfile() ? (
              <img
                src={getProfile()!}
                width={24}
                height={24}
                style={{ borderRadius: 12 }}
                alt="user portrait"
              />
            ) : (
              <PersonIcon />
            )}
          </IconButton>
        </Toolbar> */}
    </AppBar>
  );
};

export default Header;
